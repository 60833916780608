<template>
  <div class="my-consult-info">
    <div class="my-consult-info-status">
      <img v-if="consultInfo.consultStatusName == '已关闭'" src="@/assets/img/consultgbbg@2x.png" alt="图片">
      <img v-else-if="consultInfo.consultStatusName == '已受理'" src="@/assets/img/consultslbg@2x.png" alt="图片">
      <img v-else-if="consultInfo.consultStatusName == '已回复'" src="@/assets/img/consulthfbg@2x.png" alt="图片">
      <img v-else-if="consultInfo.consultStatusName == '待处理'" src="@/assets/img/consultdclbg@2x.png" alt="图片">
      <strong>{{consultInfo.consultStatusName}}</strong>
    </div>
    <div class="my-consult-info-content">
      <div>
        <strong>咨询主题</strong>
        <span>{{consultInfo.consultTitle}}</span>
      </div>
      <div>
        <strong>咨询详情</strong>
        <span>{{consultInfo.consultContent}}</span>
      </div>
      <div>
        <strong>咨询人信息</strong>
        <div>
          <span>联系人</span>
          <p>{{consultInfo.contactPerson}}</p>
        </div>
        <div>
          <span>联系电话</span>
          <p>{{consultInfo.contactDetail}}</p>
        </div>
        <div>
          <span>提交时间</span>
          <p>{{consultInfo.consultTime}}</p>
        </div>
      </div>
      <div v-if="consultInfo.consultStatus == '3'">
        <strong>回复内容</strong>
        <span>{{consultInfo.replyContent}}</span>
        <p v-for="el in consultInfo.replyAttachments" :key="el.id" @click="downloadAnnex(el.id)">
          <img src="@/assets/img/fjicon1.png" alt="fjicon1">
          <span>{{el.fileName}}</span>
          <!-- <span @click="downloadAnnex(el.id)">预览</span> -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsultInfo } from "@/api/consult";
export default {
  data(){
    return{
        consultInfo:''
    }
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadConsultInfo();
  },
  methods: {
    loadConsultInfo() {
      getConsultInfo(this.id).then((res) => {
        console.log(res);
        this.consultInfo = res
      });
    },
    downloadAnnex (val) {
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${val}`);
    }
  },
};
</script>

<style lang="scss" scoped>
.my-consult-info {
  min-height: calc(100vh - 56px);
  padding-bottom: 16px;
  background: #F6F6F6;
  .my-consult-info-status{
    height: 140px;
    position: relative;
    padding: 24px 32px;
    >strong{
        display: block;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        position: relative;
        z-index: 2;
    }
    >img{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
  }
  .my-consult-info-content{
    position: relative;
    z-index: 2;
    margin: -72px 16px 0;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    >div{
        padding-bottom: 16px;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 16px;
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom:0;
        }
        >strong{
            display: block;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-bottom: 12px;
        }
        >span{
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
        }
        >p{
          display: flex;
          font-size: 12px;
          line-height: 18px;
          margin-top: 6px;
          >img{
              width: 14px;
              height: 14px;
              margin-right: 6px;
              margin-top: 2px;
            }
          >span{
            flex: 1;
            // &:first-child{
            //   max-width: 70%;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            //   white-space: nowrap;
            // }
            // &:last-child{
            //   color: #ea0b06;
            // }
          }
        }
        >div{
            display: flex;
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0;
            }
            >span{
                width: 108px;
                font-size: 12px;
                line-height: 18px;
                color: #999999;
            }
            >p{
                font-size: 12px;
                line-height: 18px;
                color: #333333;
            }
        }
    }
  }
}
</style>