import { PortalRequest as request } from '@/utils/request'

// 分页查看我的咨询
export const getConsultList = (params, data) => {
  return request({
    url: '/consult/page-list',
    method: 'post',
    params: params,
    data
  })
}

// 分页查看我的咨询
export const getConsultInfo = (id) => {
  return request({
    url: `/consult/${id}`,
    method: 'get',
  })
}
